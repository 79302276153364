// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-template-js": () => import("/opt/build/repo/src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-offer-template-js": () => import("/opt/build/repo/src/templates/offer-template.js" /* webpackChunkName: "component---src-templates-offer-template-js" */),
  "component---src-templates-news-template-js": () => import("/opt/build/repo/src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-offer-js": () => import("/opt/build/repo/src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

